<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template
      :heading="$t('adipositas')"
      :next="next"
      :previous="previous"
      :sources="sources"
    >
      <p v-for="paragraph in $t('disorders-adipositas')" :key="paragraph">
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "adipositas",
  components: {
    DisorderTemplate,
    Breadcrumbs
  },
  data: () => ({
    next: {
      slug: "orthorexia",
      url: "/disorders/orthorexia"
    },
    previous: {
      slug: "binge-eating",
      url: "/disorders/binge-eating"
    },
    sources: [
      "[1] Adipositas Gesellschaft. Definition von Adipositas – Adipositas Gesellschaft. Accessed July 9, 2021. https://adipositas-gesellschaft.de/ueber-adipositas/definition-von-adipositas/"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("adipositas"),
          disabled: true
        }
      ];
    }
  }
};
</script>
